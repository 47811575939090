import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import {SERVER_URL} from './constants/server-url';
import {configuration} from './constants/webrtc';
import {LOGIN, SEARCH_PREFERENCES} from './constants/storage-keys';
import {State} from './constants/client-state';
import {GENDERS, GENDER_BUTTON_TITLE, SEARCH_BUTTON_TITLE, Gender} from './constants/gender';
import {DEFAULT_SEARCH_PREF, SearchPreferences} from './constants/search-preferences';

let socket: WebSocket;

function App() {
    const _pingPongTimeout = useRef<NodeJS.Timeout>();
    const [login, setLogin] = useState<string|null>(localStorage.getItem(LOGIN) ?? null);
    const [state, setState] = useState<State>(State.Idle);
    const [isMicMuted, setIsMicMuted] = useState<boolean>(false);
    const [buttonClickTimout, setButtonClickTimeout] = useState<boolean>(false);
    const [searchPref, setSearchPref] = useState<SearchPreferences>(JSON.parse(localStorage.getItem(SEARCH_PREFERENCES) ?? DEFAULT_SEARCH_PREF));
    const [callState, setCallState] = useState<RTCPeerConnectionState|''>('');
    const audioRef = useRef<HTMLAudioElement>(null);
    const peerConnection = useRef<RTCPeerConnection>();
    const stream = useRef<MediaStream>();

    const updateLogin = (uid: string): void => {
        localStorage.setItem(LOGIN, uid);
        setLogin(uid);
    }
    const ping = (): void => {
        if (!_pingPongTimeout.current) {
            _pingPongTimeout.current = setTimeout(() => {
                // Socket is dead
                setUpSocketConnection();
                _pingPongTimeout.current = undefined;
            }, 5000);
        }
    }
    const setUpSocketConnection = () => {
        // Set up socket connection
        let url: URL|string = SERVER_URL;
        if (login) {
            // If client has login then add it as a query to the url
            url = new URL(SERVER_URL);
            url.search = new URLSearchParams({uid: login}).toString();
        }
        socket = new WebSocket(url);
        if (!socket) {
            console.log("Server didn't accept socket");
        } else {
            // Set up socket events
            socket.addEventListener("open", () => {});
            socket.addEventListener("message", async (event) => {
                const data = JSON.parse(event.data);
                console.log('receive', data.type);
                switch (data.type) {
                    case 'auth':
                        if (!login) {
                            updateLogin(data.uid);
                        }
                        break;
                    case 'start-search':
                        if (data.banned) {
                            alert("You are banned");
                        } else {
                            setState(State.Searching)
                        }
                        break;
                    case 'stop-search':
                        setState(State.Idle)
                        break;
                    case 'dialog-start':
                        setState(State.InDialog);
                        try {
                            peerConnection.current = new RTCPeerConnection(configuration);
                            stream.current?.getTracks().forEach(track => {
                                if (stream.current) {
                                    peerConnection.current?.addTrack(track, stream?.current);
                                }
                            });
                            peerConnection.current.addEventListener('icecandidate', event => {
                                if (event.candidate) {
                                    socketSend('webrtc', {iceCandidate: event.candidate});
                                }
                            });
                            peerConnection.current.addEventListener('connectionstatechange', event => {
                                setCallState(peerConnection.current?.connectionState ?? '');
                                console.log(peerConnection.current?.connectionState);
                                if (peerConnection.current?.connectionState === 'failed') {
                                    socketSend("dialog-close");
                                }
                            });
                            peerConnection.current.addEventListener('track', async (event) => {
                                const [remoteStream] = event.streams;
                                if (audioRef && audioRef.current) {
                                    audioRef.current!.srcObject = remoteStream;
                                }
                            });
                            peerConnection.current?.addEventListener('iceconnectionstatechange', async (event) => {
                               if (peerConnection.current?.iceConnectionState === "disconnected") {
                                   const offer = await peerConnection.current.createOffer({iceRestart: true});
                                   await peerConnection.current.setLocalDescription(offer);
                                   socketSend('webrtc', {offer});
                               }
                            });
                            if (data.initiate) {
                                const offer = await peerConnection.current.createOffer();
                                await peerConnection.current.setLocalDescription(offer);
                                socketSend('webrtc', {offer});
                            }
                        } catch (error) {
                            console.error('Error accessing media devices.', error);
                        }
                        break;
                    case 'dialog-close':
                        disableMicIndicator();
                        setState(State.Idle);
                        closePeerConnection();
                        break;
                    case 'webrtc':
                        const payload = data.payload;
                        if (payload.answer) {
                            const remoteDesc = new RTCSessionDescription(payload.answer);
                            await peerConnection.current?.setRemoteDescription(remoteDesc);
                        } else if (payload.offer) {
                            peerConnection.current?.setRemoteDescription(new RTCSessionDescription(payload.offer));
                            const answer = await peerConnection.current?.createAnswer();
                            await peerConnection.current?.setLocalDescription(answer);
                            socketSend('webrtc', {answer});
                        } else if (payload.iceCandidate) {
                            try {
                                await peerConnection.current?.addIceCandidate(payload.iceCandidate);
                            } catch (e) {
                                console.error('Error adding received ice candidate', e);
                            }
                        }
                        break;
                    case 'pong':
                        if (_pingPongTimeout.current) {
                            clearTimeout(_pingPongTimeout.current);
                            _pingPongTimeout.current = undefined;
                        }
                        break;
                }
            });
            socket.addEventListener("error", () => {
                console.log("socket error");
            });
            socket.addEventListener("close", () => {
                console.log("socket close");
                if (state === State.InDialog) {
                    closePeerConnection();
                }
                setState(State.Idle);
            });
        }
    }
    const disableMicIndicator = () => {
        if (stream.current) {
            stream.current.getTracks().forEach(track => track.stop());
        }
    }

    const initStream = async () => {
        const openMediaDevices = async (constraints: MediaStreamConstraints | undefined) => {
            return navigator.mediaDevices.getUserMedia(constraints);
        }
        stream.current = await openMediaDevices({'audio':true});
    }
    const closePeerConnection = () => {
        peerConnection.current?.close();
        peerConnection.current = undefined;
    }
    useEffect(() => {
        setUpSocketConnection();
    }, []);
    useEffect(() => {
        activeButtonTimeout();
        if (state !== State.InDialog) {
            setCallState('');
        }
    }, [state]);
    useEffect(() => {
        localStorage.setItem(SEARCH_PREFERENCES, JSON.stringify(searchPref));
        socketSend('search-preferences', {searchPref});
    }, [searchPref]);

    const socketSend = (type: string, data: Record<string, any> = {}) => {
        ping();
        if (socket.readyState === WebSocket.OPEN) {
            socket?.send(JSON.stringify({
                type: type,
                payload: data,
            }));
        } else {
            console.warn("websocket is not connected");
            setUpSocketConnection();
        }

    }

    const activeButtonTimeout = () => {
        setButtonClickTimeout(true);
        setTimeout(() => setButtonClickTimeout(false), 500);
    }

    return (
        <div className="App">
            <div>
                uid: {login}
            </div>
            <div className="gender-container">
                <div className="gender-selector">
                    Your gender
                    {GENDERS.map((gender) => {
                        return <button
                            className={searchPref.your === gender ? "button-selected" : ""}
                            key={gender}
                            disabled={state === State.Searching || state === State.InDialog}
                            onClick={() => {
                                // If user clicks on dunno then set partner's gender as dunno as well
                                if (gender === Gender.Dunno) {
                                    setSearchPref(Object.assign({}, {your: gender, partner: Gender.Dunno}));
                                } else {
                                    setSearchPref(Object.assign({}, {your: gender, partner: searchPref.partner}));
                                }
                            }}
                        >
                            {GENDER_BUTTON_TITLE[gender]}
                        </button>;
                    })}
                </div>
                <div className="gender-selector">
                    Partner gender
                    {GENDERS.map((gender) => {
                        return <button
                            className={searchPref.partner === gender ? "button-selected" : ""}
                            key={gender}
                            disabled={searchPref.your === Gender.Dunno || (state === State.Searching || state === State.InDialog)}
                            onClick={() => setSearchPref(Object.assign({}, {your: searchPref.your, partner: gender}))}
                        >
                            {GENDER_BUTTON_TITLE[gender]}
                        </button>;
                    })}
                </div>
            </div>
            <div>
                <div>
                    {callState}
                </div>
                <button
                    onClick={async () => {
                        if (!login) {
                            console.log('You are not logged');
                            setUpSocketConnection();
                            return;
                        }
                        if (state === State.Searching) {
                            disableMicIndicator();
                            socketSend("stop-search");
                        } else if (state === State.Idle) {
                            await initStream();
                            socketSend("start-search");
                        } else if (state === State.InDialog) {
                            socketSend("dialog-close");
                            disableMicIndicator();
                        }
                    }}
                    disabled={buttonClickTimout}
                >
                    {SEARCH_BUTTON_TITLE[state]}
                </button>
                {state === State.InDialog && <div>
                    <button
                        onClick={() => {
                            if (stream.current) {
                                stream.current.getAudioTracks()[0].enabled = isMicMuted;
                                setIsMicMuted(!isMicMuted);
                            }
                        }}
                    >
                        {isMicMuted ? 'Unmute' : 'Mute'}
                    </button>
                    <button
                        onClick={() => {
                            socketSend("report");
                        }}
                    >
                        Report
                    </button>
                </div>}
                <audio ref={audioRef} id="localAudio" autoPlay />
            </div>
        </div>
    );
}

export default App;
