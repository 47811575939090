import {State} from './client-state';

export enum Gender {
    Dunno = "dunno",
    Male = "male",
    Female = "female",
}

export const SEARCH_BUTTON_TITLE = {
    [State.Idle]: "START SEARCHING",
    [State.Searching]: "STOP SEARCHING",
    [State.InDialog]: "CLOSE DIALOG",
}

export const GENDER_BUTTON_TITLE = {
    [Gender.Dunno]: "Dunno",
    [Gender.Male]: "Male",
    [Gender.Female]: "Female",
}

export const GENDERS = [Gender.Dunno, Gender.Male, Gender.Female];